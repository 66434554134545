<template>
    <!-- 打样需求 -->
    <div>
        <el-form :model="ruleForm" :rules="rules" ref="formCheck" label-width="140px" class="login-form">
            <div class="block">
                <div class="header">基本信息</div>
                <div class="list">
                    <el-form-item label="需求标题" prop="title">
                        <el-input style="max-width:350px;" placeholder="请输入标题" v-model="ruleForm.title"></el-input>
                    </el-form-item>
                </div>
            </div>
            <div class="block">
                <div class="header">打样商品信息</div>
                <div style="padding:0 20px;">
                    <div v-for="(item,idx) in ruleForm.dayang.items" :key="idx" class="dayang-item">
                        <div style="padding:8px 30px;line-height:40px;" class="flex">
                            <div>商品{{idx+1}}</div>
                            <div v-if="idx>0" style="text-align:right;" class="flex-1">
                                <i @click="delGoods(idx)" style="font-size:20px;color:#016FFF;" class="iconfont icon-zu9"></i>
                            </div>
                        </div>
                        <el-form-item label="打样商品类目" :prop="'dayang.items.'+ idx +'.catId'" :rules="rules.catId">
                            <el-cascader :options="cats" :show-all-levels="false" :props="{value:'key',label:'title',emitPath:false}"
                                v-model="item.catId" placeholder="请选择商品类目">
                            </el-cascader>
                        </el-form-item>
                        <el-form-item label="打样商品风格" :prop="'dayang.items.'+ idx +'.styleId'" :rules="rules.styleId">
                            <el-select v-model="item.styleId" placeholder="请选择商品风格">
                                <el-option v-for="item in styles" :key="item.id" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="打样样品数量" :prop="'dayang.items.'+ idx +'.count'" :rules="rules.count">
                            <!-- <el-input style="width:300px;" type="number" min="1" v-model="item.count" placeholder="请输入样品数量"></el-input> -->
                            <el-input-number v-model="item.count" :min="1" placeholder="请输入样品数量"></el-input-number>
                        </el-form-item>
                        <el-form-item label="渲染图" :prop="'dayang.items.'+ idx +'.renderImgs'" :rules="rules.renderImgs">
                            <div>
                                <UploadImgs v-if="showImgs" :limit="5" v-model="item.renderImgs" prefix='requirement/dayang/render'></UploadImgs>
                            </div>
                        </el-form-item>
                        <el-form-item label="尺寸图">
                            <div>
                                <UploadImgs v-if="showImgs" :limit="5" v-model="item.sizeImgs" prefix='requirement/dayang/size'></UploadImgs>
                            </div>
                        </el-form-item>
                        <el-form-item label="CAD文件" style="margin-bottom:12px;">
                            <div style="position:relative;">
                                <UploadCads v-if="showImgs" v-model="item.cadFiles" prefix='requirement/dayang/cad'></UploadCads>
                            </div>
                        </el-form-item>
                    </div>
                    <div class="addStyle flex flex-align-center flex-pack-center">
                        <div @click="addGoods()">
                            <span>+</span>
                            <span>继续添加打样商品</span>
                        </div>
                    </div>
                </div>
            </div>
            <Gynls v-if="ruleForm.dayang.gybz" v-model="ruleForm.dayang.gybz" ref="gynls"></Gynls>
            <div class="block">
                <div class="header">其他需求说明</div>
                <div style="padding:20px 20px 5px 20px;">
                    <el-form-item prop="dayang.desc" label="打样要求描述">
                        <el-input type="textarea" :rows="4" v-model="ruleForm.dayang.desc" style="width:80%" placeholder="请填写打样要求描述详情"></el-input>
                    </el-form-item>
                </div>
            </div>
            <div class="block">
                <div class="header">付款</div>
                <div style="padding:20px 20px 5px 20px;">
                    <el-form-item prop="dayang.payType" label="付款方式">
                        <el-radio-group v-model="ruleForm.dayang.payType">
                            <el-radio v-for="(item,idx) in payTypes" :key="idx" :label="item.code">{{item.desc}}</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </div>
            </div>
            <div class="block">
                <div class="header">验收方式和时间</div>
                <div style="padding:20px 20px 5px 20px;">
                    <el-form-item label="验收时间" prop="dayang.checkDate">
                        <el-date-picker v-model="ruleForm.dayang.checkDate"
                            type="date" placeholder="选择日期"></el-date-picker>
                    </el-form-item>
                    <el-form-item label="验收方式" prop="dayang.checkType">
                        <el-radio-group v-model="ruleForm.dayang.checkType">
                            <el-radio :label="1">样品寄送验收</el-radio>
                            <el-radio :label="2">视频验收</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="收货人" prop="dayang.reciver">
                        <el-input style="max-width:350px;" placeholder="请输入收货人姓名" v-model="ruleForm.dayang.reciver"></el-input>
                    </el-form-item>
                    <el-form-item label="寄送地址" prop="dayang.address">
                        <el-input style="max-width:350px;" placeholder="请输入寄送地址" v-model="ruleForm.dayang.address"></el-input>
                    </el-form-item>
                    <el-form-item label="联系方式" prop="dayang.contact">
                        <el-input style="max-width:350px;" placeholder="请输入联系方式" v-model="ruleForm.dayang.contact"></el-input>
                    </el-form-item>
                </div>
            </div>
            <div v-if="$route.query.id" style="padding:10px 20px 30px 20px;">
                <el-button size="small" type="primary" @click="submitForm(1)">保存</el-button>
                <el-button size="small" type="primary" @click="back()">取消</el-button>
            </div>
            <div v-else style="padding:10px 20px 30px 20px;">
                <el-button size="small" type="primary" @click="submitForm(1)">保存草稿</el-button>
                <el-button size="small" type="primary" @click="submitForm(2)">立即发布</el-button>
            </div>
        </el-form>
    </div>
</template>
<script>
    import _ from 'lodash';
    import { IMG_URL_PRE } from '@/config';
    import { fetchTree } from '@/util';
    import { getCats,getStyles } from '@/service/item';
    import { getPayType } from '@/service/pay';
    import { add_tmpRequire,add_pubRequire,getRequireId,editRequire } from '@/service/want';
    import UploadImgs from '@/components/upload/UploadImgs.vue';
    import UploadCads from '@/components/upload/UploadCads.vue';
    import Gynls from './gynl/Gynls.vue';
    export default {
        components: {
            UploadImgs,UploadCads,Gynls
        },
        data() {
            let checkNum = (rule, value, callback) => {
                if (!value) {
                return callback(new Error('请输入样品数量'));
                } else {
                    const reg = /^[1-9]\d*$/
                    if (reg.test(value)) {
                        callback();
                    } else {
                        return callback(new Error('请填写正整数'));
                    }
                }
            };
            return {
                rules: {
                    title: [{ required: true, message: '请输入需求标题', trigger: 'blur' }],
                    catId: [{ required: true, message: '请选择商品类目', trigger: 'blur' }],
                    styleId: [{ required: true, message: '请选择商品风格', trigger: 'blur' }],
                    count: [{validator: checkNum, trigger: 'blur'}],
                    renderImgs: [{ required: true, message: '请上传渲染图', trigger: 'blur' }],
                    [`dayang.desc`]: [{ required: true, message: '请填写打样要求描述详情', trigger: 'blur' }],
                    [`dayang.payType`]: [{ required: true, message: '请选择付款方式', trigger: 'blur' }],
                    [`dayang.checkDate`]: [{ required: true, message: '请选择验收时间', trigger: 'blur' }],
                    [`dayang.checkType`]: [{ required: true, message: '请选择验收方式', trigger: 'blur' }],
                    [`dayang.reciver`]: [{ required: true, message: '请输入收货人姓名', trigger: 'blur' }],
                    [`dayang.address`]: [{ required: true, message: '请输入寄送地址', trigger: 'blur' }],
                    [`dayang.contact`]: [{ required: true, message: '请输入联系方式', trigger: 'blur' }],
                },
                ruleForm:{
                    type:"dayang",
                    title:"",
                    dayang:{
                        desc:null,
                        payType:null,
                        checkDate:null,
                        checkType:null,
                        reciver:null,
                        address:null,
                        contact:null,
                        items:[
                            {
                                catId:null,
                                styleId:null,
                                count:null,
                                renderImgs:[],
                                cadFiles:[],
                                sizeImgs:[],
                            }
                        ],
                        gybz:[
                            // {
                            //     id:null,
                            //     name:null,
                            //     quota:[
                            //         {
                            //             id:null,
                            //             name:null,
                            //             value:null,
                            //         }
                            //     ],
                            // }
                        ],
                    }
                },
                cats:[],
                styles:[],
                payTypes:[],
                showImgs:false,
                allSelectId:[],
            }
        },
        async created() {
            let catrst = await getCats();
            this.cats = fetchTree(catrst, null, { name: { dest: 'title' }, id: { dest: 'key' }, parent: 'parentId' });
            this.payTypes = await getPayType();
            this.styles = await getStyles();

            if (this.$route.query.id) {
                this.ruleForm = await getRequireId(this.$route.query.id);
                console.log(this.ruleForm);
                this.showImgs = true;
            } else this.showImgs = true;
        },
        mounted() {
            
        },
        methods: {
            back() {
                this.$router.go(-1);
            },
             // 添加商品
            addGoods() {
                let obj = {
                    catId:null, styleId:null, count:null, 
                    renderImgs:[], cadFiles:[], sizeImgs:[],
                };
                this.ruleForm.dayang.items.push(obj);
            },
            delGoods(index) {
                this.$confirm('确认是否删除该商品?', '温馨提示', { confirmButtonText: '确定',
                cancelButtonText: '取消', type: 'warning',
                }).then(() => {
                    this.$message({ type: 'success', message: '删除成功!' });
                    this.ruleForm.dayang.items.splice(index,1);
                });
            },
            submitForm(type) {
                this.$refs['formCheck'].validate((valid) => {
                    if (valid) {
                        this.submit(type);
                    }
                });
            },
            submit (type){
                const _loading = this.$loading({
                    lock: true,
                    text: '拼命加载中......',
                });
                let _item = _.clone(this.ruleForm);
                _item.dayang.checkDate = new Date(_item.dayang.checkDate).getTime();
                console.log(_item);
                if (type == 1) {
                    if (this.$route.query.id) {
                        editRequire(_item).then(rst => {
                            _loading.close();
                            this.$message.success("保存成功");
                            this.back();
                        }).catch(err => {
                            _loading.close();
                            this.$message.error(err.message);
                        });
                    } else {
                        add_tmpRequire(_item).then(rst => {
                            _loading.close();
                            this.$message.success("保存草稿成功");
                            this.$router.push('/want/wantFollow');
                        }).catch(err => {
                            _loading.close();
                            this.$message.error(err.message);
                        });
                    }
                } else if (type == 2) {
                    add_pubRequire(_item).then(rst => {
                        _loading.close();
                        this.$message.success('发布成功');
                        this.$router.push('/want/wantFollow');
                    }).catch(err => {
                        _loading.close();
                        this.$message.error(err.message);
                    });
                }
            },
        }
    }
</script>
<style scoped src="../style.css"></style>
<style scoped>
    .login-form>>> input[type=number] {
        padding: 0 0 0 15px;
    }
</style>
<style scoped lang="less">
    .list {
        padding: 10px 20px;
    }
    .list>div {
        line-height: 40px;
    }
    .list>div>div:first-child {
        width: 126px;
        text-align: right;
        color: #000;
        margin-right: 10px;
    }
    .dayang-item {
        border-bottom: 1px dashed #E6E6E6;
    }
    .addStyle {
        padding: 25px 0;
        text-align: center;
        cursor: pointer;
        span {
            display: inline-block;
            vertical-align: top;
            font-size: 14px;
            color: #016FFF;
            &:first-child {
                width: 18px;
                height: 18px;
                line-height: 15px;
                text-align: center;
                font-size: 18px;
                color: #016FFF;
                border: 1px solid #016FFF;
                border-radius: 50%;
                position: relative;
                top: 2px;
                margin-right: 5px;
            }
        }
    }
    .gybz-item {
        border-bottom: 1px dashed #E6E6E6;
    }
</style>