<template>
    <!-- 需求总页面 -->
    <div class="zt-page-content">
        <div style="min-width:1070px;">
            <div class="tab_menu flex">
                <div v-for="(item,index) in tabList" :key="index" @click="tab(index)" class="flex menu_item" :class="{'cur':tabType == item.type}">
                    <div class="menu_item_button">{{item.name}}</div>
                </div>
            </div>
            <div>
                <Issue1 v-if="tabType == 'peitao'"></Issue1>
                <Issue2 v-if="tabType == 'gaikuang'"></Issue2>
                <Issue3 v-if="tabType == 'dayang'"></Issue3>
                <Issue4 v-if="tabType == 'dingzhi'"></Issue4>
                <Issue5 v-if="tabType == 'kaocha'"></Issue5>
                <Issue6 v-if="tabType == 'jiance'"></Issue6>
            </div>
        </div>
    </div>
</template>
<script>
    import _ from 'lodash';
    import { IMG_URL_PRE } from '@/config';
    import Issue1 from './Issue1.vue';
    import Issue2 from './Issue2.vue';
    import Issue3 from './Issue3.vue';
    import Issue4 from './Issue4.vue';
    import Issue5 from './Issue5.vue';
    import Issue6 from './Issue6.vue';
    export default {
        components: {
            Issue1,Issue2,Issue3,Issue4,Issue5,Issue6
        },
        data() {
            return {
                tabList:[
                    // {type:"peitao",name:"加工需求"},
                    // {type:"gaikuang",name:"设计需求"},
                    // {type:"dayang",name:"打样"},
                    // {type:"dingzhi",name:"找工厂定制"},
                    // {type:"kaocha",name:"实地待考察"},
                    // {type:"jiance",name:"待质量和货期检测"},
                ],
                tabIndex:0,
                tabType:null,
            }
        },
        watch: {
            $route: {
                handler() {
                    this.item("edit");
                },
                deep: true
            },
        },
        created() {
            this.item();
        },
        mounted() {
            console.log(this.$route.query.type);
        },
        methods: {
            item(edit) {
                if (edit == "edit") this.tabType = null;
                this.comp = this.$store.state.loginUser.comp;
                if (this.comp && this.comp.comp_type) {
                    switch(this.comp.comp_type) {
                        case 1:
                            this.tabList = [
                                {type:"dayang",name:"打样"},
                                {type:"gaikuang",name:"设计需求"},
                            ];
                            this.$nextTick(()=>{
                                this.tabType = "dayang";
                            })
                            break;
                        case 3:
                            this.tabList = [
                                {type:"peitao",name:"加工需求"},
                                {type:"gaikuang",name:"设计需求"},
                            ];
                            this.$nextTick(()=>{
                                this.tabType = "peitao";
                            })
                            break;
                    }
                }
                if (this.$route.query.type) {
                    let tabList=this.tabList.find((e)=> {
                        return e.type == this.$route.query.type;
                    })
                    this.tabList = [tabList];
                    this.$nextTick(()=>{
                        this.tabType = this.$route.query.type;
                    })
                }
            },
            goto(link) {
                this.$router.push(link)
            },
            back() {
                this.$router.go(-1)
            },
            // 选项卡
            tab(index) {
                this.tabIndex = index;
                this.tabType = this.tabList[index].type;
            },
            submitForm() {
                this.$refs['formCheck'].validate((valid) => {
                    if (valid) {
                        this.submit();
                    }
                });
            },
            submit() {
                this.back();
                this.$message.success("保存成功，请等待审核结果");
            },
        }
    }
</script>
<style scoped src="../style.css"></style>
<style scoped>
    /*----选项卡----*/
    .tab_menu {
        background: #fff;
        box-shadow:0px 2px 4px 0px rgba(209,217,226,1);
        padding: 20px 5px 0 5px;
        margin: 20px;
    }
    .tab_menu .menu_item{
        height: 60px;
        line-height: 60px;
        margin: 0 15px;
        cursor: pointer;
        font-size: 14px;
        color: #808080;
    }
    .tab_menu .menu_item.cur{
        color: #4D4D4D;
        border-bottom: 2px solid rgba(131,98,228,1);
        /* background:linear-gradient(90deg,rgba(131,98,228,1) 0%,rgba(80,116,238,1) 100%); */
    }
    .menu_item_button {
        width:140px;
        height:40px;
        line-height:40px;
        text-align:center;
        border-radius:25px;
        box-shadow:0px 2px 10px 0px rgba(167,153,153,0.5);
        font-size: 15px;
        color: #fff;
    }
    .tab_menu>.menu_item:nth-child(1) .menu_item_button {
        background:linear-gradient(90deg,rgba(255,178,150,1) 0%,rgba(253,136,149,1) 100%);
    }
    .tab_menu>.menu_item:nth-child(2) .menu_item_button {
        background:linear-gradient(90deg,rgba(124,191,246,1) 0%,rgba(39,147,230,1) 100%);
    }
    .tab_menu>.menu_item:nth-child(3) .menu_item_button {
        background:linear-gradient(270deg,rgba(80,126,207,1) 0%,rgba(154,161,235,1) 100%);
    }
    .tab_menu>.menu_item:nth-child(4) .menu_item_button {
        background:linear-gradient(270deg,rgba(70,184,165,1) 0%,rgba(128,223,174,1) 100%);
    }
    .tab_menu>.menu_item:nth-child(5) .menu_item_button {
        background:linear-gradient(90deg,rgba(191,115,213,1) 0%,rgba(142,57,204,1) 100%);
    }
    .tab_menu>.menu_item:nth-child(6) .menu_item_button {
        background:linear-gradient(270deg,rgba(219,162,76,1) 0%,rgba(250,196,96,1) 100%,rgba(250,196,96,1) 100%);
    }
</style>