<template>
    <!-- 加工需求 -->
    <div>
        <el-form :model="ruleForm" :rules="rules" ref="formCheck" label-width="100px" class="login-form">
            <div class="block">
                <div class="header">基本信息</div>
                <div class="list">
                    <el-form-item label="需求标题" prop="title">
                        <el-input style="max-width:350px;" placeholder="请输入标题" v-model="ruleForm.title"></el-input>
                    </el-form-item>
                    <el-form-item label="需求类目" prop="peitao.catId">
                        <el-cascader :options="cats" :show-all-levels="false" :props="{value:'key',label:'title',emitPath:false}"
                            v-model="ruleForm.peitao.catId" placeholder="请选择商品类目">
                        </el-cascader>
                    </el-form-item>
                </div>
            </div>
            <div class="block">
                <div class="header">产品图片</div>
                <div style="padding:20px 20px 5px 20px;">
                    <el-form-item label="产品" prop="peitao.productImgs"  label-width="95px">
                        <div style="padding:0;">
                            <!-- <UploadImgs v-if="item.design_photos && item.design_photos.length>0" v-model="item.design_photos"></UploadImgs> -->
                            <UploadImgs v-if="showImgs" v-model="ruleForm.peitao.productImgs" prefix='requirement/peitao/product'></UploadImgs>
                        </div>
                        <!-- <div class="font14-grey" v-if="ruleForm.peitao.productImgs" style="position:absolute;right:0px;bottom:0px;">{{ruleForm.peitao.productImgs.length || 0}}/5</div> -->
                    </el-form-item>
                    <el-form-item prop="peitao.urls" label="参考链接">
                        <el-input style="width:300px;" v-model="ruleForm.peitao.urls[0]" placeholder="请输入商品链接"></el-input>
                    </el-form-item>
                    <el-form-item prop="peitao.desc" label="需求说明">
                        <el-input type="textarea" :rows="4" v-model="ruleForm.peitao.desc" style="width:80%" placeholder="请描述需求详情，如需要的木材种类，是否需要提前打样等"></el-input>
                    </el-form-item>
                </div>
            </div>
            <div class="block">
                <div class="header">数量和价格</div>
                <div style="padding:20px 20px 5px 20px;">
                    <el-form-item prop="peitao.type" label="需求类型">
                        <el-radio-group v-model="ruleForm.peitao.type">
                            <el-radio :label="1">单次需求</el-radio>
                            <el-radio :label="2">每月需求</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item prop="peitao.count" label="需求量">
                        <el-input-number v-model="ruleForm.peitao.count" :min="1" placeholder="请输入需求量"></el-input-number>
                    </el-form-item>
                    <el-form-item prop="peitao.prices" label="价格范围">
                        <el-input style="width:130px;" type="number" min="1" :max="ruleForm.peitao.prices[1]" v-model="ruleForm.peitao.prices[0]" placeholder="价格"></el-input>~
                        <el-input style="width:130px;" type="number" min="1" v-model="ruleForm.peitao.prices[1]" placeholder="价格"></el-input>
                    </el-form-item>
                </div>
            </div>
            <div class="block">
                <div class="header">时间</div>
                <div style="padding:20px 20px 5px 20px;"> 
                    <el-form-item label="交货时间">
                        <el-date-picker v-model="ruleForm.peitao.giveDate"
                            type="date" placeholder="选择日期"></el-date-picker>
                    </el-form-item>
                </div>
            </div>
            <div v-if="$route.query.id" style="padding:10px 20px 30px 20px;">
                <el-button size="small" type="primary" @click="submitForm(1)">保存</el-button>
                <el-button size="small" type="primary" @click="back()">取消</el-button>
            </div>
            <div v-else style="padding:10px 20px 30px 20px;">
                <el-button size="small" type="primary" @click="submitForm(1)">保存草稿</el-button>
                <el-button size="small" type="primary" @click="submitForm(2)">立即发布</el-button>
            </div>
        </el-form>
    </div>
</template>
<script>
    import _ from 'lodash';
    import { IMG_URL_PRE } from '@/config';
    import { fetchTree } from '@/util';
    import { getCats } from '@/service/item';
    import { add_tmpRequire,add_pubRequire,getRequireId,editRequire } from '@/service/want';
    import UploadImgs from '@/components/upload/UploadImgs.vue';
    export default {
        components: {
            UploadImgs
        },
        data() {
            let checkNum = (rule, value, callback) => {
                if (!value) {
                return callback(new Error('请输入需求量'));
                } else {
                    const reg = /^[1-9]\d*$/
                    if (reg.test(value)) {
                        callback();
                    } else {
                        return callback(new Error('请填写正整数'));
                    }
                }
            };
            let checkPrice = (rule, value, callback) => {
                if (!value || value.length==0) {
                    return callback(new Error('请填写价格范围'));
                } else {
                    if (value.length>1 && value[0] && value[1]) {
                        callback();
                    } else {
                        return callback(new Error('请完善价格范围'));
                    }
                }
            };
            return {
                rules: {
                    [`title`]: [{ required: true, message: '请输入需求标题', trigger: 'blur' }],
                    [`peitao.catId`]: [{ required: true, message: '请输入需求类目', trigger: 'blur' }],
                    [`peitao.productImgs`]: [{ required: true, message: '请上传产品图', trigger: 'blur' }],
                    [`peitao.count`]: [{ required: true, validator: checkNum, trigger: 'blur'}],
                    [`peitao.prices`]: [{ required: true, validator: checkPrice, trigger: 'blur' }],
                },
                ruleForm:{
                    type:"peitao",
                    title:"",
                    peitao:{
                        catId:"",
                        productImgs:[],
                        urls:[],
                        desc:"",
                        type:1,
                        count:"",
                        prices:[],
                        giveDate:"",
                    }
                },
                cats:[],
                showImgs:false,
            }
        },
        async created() {
            getCats().then(rst => {
                this.cats = fetchTree(rst, null, { name: { dest: 'title' }, id: { dest: 'key' }, parent: 'parentId' });
                console.log(this.cats);
            });
        },
        mounted() {
            // console.log(this.$route.query.id);
            if (this.$route.query.id) {
                getRequireId(this.$route.query.id).then(rst => {
                    this.item = rst;
                    this.ruleForm = {
                        id:rst.id,
                        type:"peitao",
                        title:rst.title,
                        peitao:{
                            catId:rst.peitao.catId,
                            productImgs:rst.peitao.productImgs,
                            urls:rst.peitao.urls,
                            desc:rst.peitao.desc,
                            type:rst.peitao.type,
                            count:rst.peitao.count,
                            prices:rst.peitao.prices,
                            giveDate:rst.peitao.giveDate,
                        }
                    }
                    this.showImgs = true;
                }).catch(err => {
                    console.log(err);
                });
            } else this.showImgs = true;
        },
        methods: {
            goto(link) {
                this.$router.push(link)
            },
            back() {
                this.$router.go(-1)
            },
            // 选项卡
            tab(index) {
                this.tabIndex = index;
            },
            select(index) {
                this.selectIndex = index;
            },
            submitForm(type) {
                this.$refs['formCheck'].validate((valid) => {
                    if (valid) {
                        this.submit(type);
                    }
                });
            },
            submit(type){
                const _loading = this.$loading({
                    lock: true,
                    text: '拼命加载中......',
                });
                console.log(_item);
                let _item = _.clone(this.ruleForm);
                _item.peitao.giveDate = new Date(_item.peitao.giveDate).getTime();
                console.log(_item);
                if (type == 1) {
                    if (this.$route.query.id) {
                        editRequire(_item).then(rst => {
                            _loading.close();
                            this.$message.success("保存成功");
                            this.back();
                        }).catch(err => {
                            _loading.close();
                            this.$message.error(err.message);
                        });
                    } else {
                        add_tmpRequire(_item).then(rst => {
                            _loading.close();
                            this.$message.success("保存草稿成功");
                            this.$router.push('/want/wantFollow');
                        }).catch(err => {
                            _loading.close();
                            this.$message.error(err.message);
                        });
                    }
                } else if (type == 2) {
                    add_pubRequire(_item).then(rst => {
                        _loading.close();
                        this.$message.success('发布成功');
                        this.$router.push('/want/wantFollow');
                    }).catch(err => {
                        _loading.close();
                        this.$message.error(err.message);
                    });
                }
            },
        }
    }
</script>
<style scoped src="../style.css"></style>
<style scoped>
    .list {
        padding: 10px 20px;
    }
    .list>div {
        line-height: 40px;
    }
    .list>div>div:first-child {
        width: 126px;
        text-align: right;
        color: #000;
        margin-right: 10px;
    }
    .login-form>>> input[type=number] {
        padding: 0 0 0 15px;
    }
</style>