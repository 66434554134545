<template>
    <!-- 改款需求也叫（需求定制、需求设计） -->
    <div>
        <el-form :model="ruleForm" :rules="rules" ref="formCheck" label-width="100px" class="login-form">
            <div class="block">
                <div class="header">基本信息</div>
                <div class="list">
                    <el-form-item label="需求标题" prop="title">
                        <el-input style="max-width:350px;" placeholder="请输入标题" v-model="ruleForm.title"></el-input>
                    </el-form-item>
                    <el-form-item label="需求类目" prop="gaikuang.catId">
                        <el-cascader :options="cats" :show-all-levels="false" :props="{value:'key',label:'title',emitPath:false}"
                            v-model="ruleForm.gaikuang.catId" placeholder="请选择商品类目">
                        </el-cascader>
                    </el-form-item>
                </div>
            </div>
            <div class="block">
                <div class="header">产品图片</div>
                <div style="padding:20px 20px 5px 20px;">
                    <el-form-item label="产品" prop="gaikuang.productImgs"  label-width="95px">
                        <div style="padding:0;">
                            <UploadImgs v-if="showImgs" v-model="ruleForm.gaikuang.productImgs" prefix='requirement/gaikuang/product'></UploadImgs>
                        </div>
                    </el-form-item>
                    <el-form-item prop="gaikuang.urls" label="参考链接">
                        <el-input style="width:300px;" v-model="ruleForm.gaikuang.urls[0]" placeholder="请输入商品链接"></el-input>
                    </el-form-item>
                    <el-form-item prop="gaikuang.desc" label="设计需求">
                        <el-input type="textarea" :rows="4" v-model="ruleForm.gaikuang.desc" style="width:80%" placeholder="请输入"></el-input>
                    </el-form-item>
                </div>
            </div>
            <div class="block">
                <div class="header">数量和价格</div>
                <div style="padding:20px 20px 5px 20px;">
                    <el-form-item prop="gaikuang.fileTypes" class="custom-label" label="设计类型">
                        <el-checkbox-group v-model="ruleForm.gaikuang.fileTypes">
                            <el-checkbox :label="'3d'">3d渲染图</el-checkbox>
                            <el-checkbox :label="'material'">电商素材</el-checkbox>
                            <el-checkbox :label="'size'">尺寸图 </el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                    <!-- <el-form-item prop="gaikuang.count" label="需求量">
                        <el-input style="width:300px;" type="number" min="0" v-model="ruleForm.gaikuang.count" placeholder="请输入需求量"></el-input>
                    </el-form-item> -->
                    <el-form-item prop="gaikuang.prices" label="价格范围">
                        <el-input style="width:130px;" type="number" min="1" :max="ruleForm.gaikuang.prices[1]" v-model="ruleForm.gaikuang.prices[0]" placeholder="价格"></el-input>~
                        <el-input style="width:130px;" type="number" min="1" v-model="ruleForm.gaikuang.prices[1]" placeholder="价格"></el-input>
                    </el-form-item>
                </div>
            </div>
            <div class="block">
                <div class="header">时间</div>
                <div style="padding:20px 20px 5px 20px;">
                    <el-form-item label="交货时间">
                        <el-date-picker v-model="ruleForm.gaikuang.giveDate"
                            type="date" placeholder="选择日期"></el-date-picker>
                    </el-form-item>
                </div>
            </div>
            <div v-if="$route.query.id" style="padding:10px 20px 30px 20px;">
                <el-button size="small" type="primary" @click="submitForm(1)">保存</el-button>
                <el-button size="small" type="primary" @click="back()">取消</el-button>
            </div>
            <div v-else style="padding:10px 20px 30px 20px;">
                <el-button size="small" type="primary" @click="submitForm(1)">保存草稿</el-button>
                <el-button size="small" type="primary" @click="submitForm(2)">立即发布</el-button>
            </div>
        </el-form>
    </div>
</template>
<script>
    import _ from 'lodash';
    import { IMG_URL_PRE } from '@/config';
    import { fetchTree } from '@/util';
    import { getCats } from '@/service/item';
    import { add_tmpRequire,add_pubRequire,getRequireId,editRequire } from '@/service/want';
    import UploadImgs from '@/components/upload/UploadImgs.vue';
    export default {
        components: {
            UploadImgs
        },
        data() {
            let checkPrice = (rule, value, callback) => {
                if (!value || value.length==0) {
                    return callback(new Error('请填写价格范围'));
                } else {
                    if (value.length>1 && value[0] && value[1]) {
                        callback();
                    } else {
                        return callback(new Error('请完善价格范围'));
                    }
                }
            };
            return {
                rules: {
                    [`title`]: [{ required: true, message: '请输入需求标题', trigger: 'blur' }],
                    [`gaikuang.catId`]: [{ required: true, message: '请输入需求类目', trigger: 'blur' }],
                    [`gaikuang.productImgs`]: [{ required: true, message: '请上传产品图', trigger: 'blur' }],
                    [`gaikuang.fileTypes`]: [{ required: true, message: '请选择需求类型', trigger: 'blur' }],
                    [`gaikuang.prices`]: [{ required: true, validator: checkPrice, trigger: 'blur' }],
                },
                ruleForm:{
                    type:"gaikuang",
                    title:"",
                    gaikuang:{
                        catId:"",
                        productImgs:[],
                        urls:[],
                        desc:"",
                        fileTypes:[],
                        // count:"",
                        prices:[],
                        giveDate:"",
                    }
                },
                cats:[],
                showImgs:false,
            }
        },
        async created() {
            getCats().then(rst => {
                this.cats = fetchTree(rst, null, { name: { dest: 'title' }, id: { dest: 'key' }, parent: 'parentId' });
                console.log(this.cats);
            });
        },
        mounted() {
            // console.log(this.$route.query.id);
            if (this.$route.query.id) {
                getRequireId(this.$route.query.id).then(rst => {
                    this.item = rst;
                    this.ruleForm = {
                        id:rst.id,
                        type:"gaikuang",
                        title:rst.title,
                        gaikuang:{
                            catId:rst.gaikuang.catId,
                            productImgs:rst.gaikuang.productImgs,
                            urls:rst.gaikuang.urls,
                            desc:rst.gaikuang.desc,
                            fileTypes:rst.gaikuang.fileTypes,
                            // count:rst.gaikuang.count,
                            prices:rst.gaikuang.prices,
                            giveDate:rst.gaikuang.giveDate,
                        }
                    }
                    this.showImgs = true;
                }).catch(err => {
                    console.log(err);
                });
            } else this.showImgs = true;
        },
        methods: {
            goto(link) {
                this.$router.push(link)
            },
            back() {
                this.$router.go(-1)
            },
            // 选项卡
            tab(index) {
                this.tabIndex = index;
            },
            select(index) {
                this.selectIndex = index;
            },
            submitForm(type) {
                this.$refs['formCheck'].validate((valid) => {
                    if (valid) {
                        this.submit(type);
                    }
                });
            },
            submit(type){
                const _loading = this.$loading({
                    lock: true,
                    text: '拼命加载中......',
                });
                console.log(_item);
                let _item = _.clone(this.ruleForm);
                _item.gaikuang.giveDate = new Date(_item.gaikuang.giveDate).getTime();
                console.log(_item);
                if (type == 1) {
                    if (this.$route.query.id) {
                        editRequire(_item).then(rst => {
                            _loading.close();
                            this.$message.success("保存成功");
                            this.back();
                        }).catch(err => {
                            _loading.close();
                            this.$message.error(err.message);
                        });
                    } else {
                        add_tmpRequire(_item).then(rst => {
                            _loading.close();
                            this.$message.success("保存草稿成功");
                            this.$router.push('/want/wantFollow');
                        }).catch(err => {
                            _loading.close();
                            this.$message.error(err.message);
                        });
                    }
                } else if (type == 2) {
                    add_pubRequire(_item).then(rst => {
                        _loading.close();
                        this.$message.success('发布成功');
                        this.$router.push('/want/wantFollow');
                    }).catch(err => {
                        _loading.close();
                        this.$message.error(err.message);
                    });
                }
            },
        }
    }
</script>
<style scoped src="../style.css"></style>
<style scoped>
    .list {
        padding: 10px 20px;
    }
    .list>div {
        line-height: 40px;
    }
    .list>div>div:first-child {
        width: 126px;
        text-align: right;
        color: #000;
        margin-right: 10px;
    }
    .login-form>>> input[type=number] {
        padding: 0 0 0 15px;
    }
</style>
<style lang="less" scoped>
.custom-label {
  /deep/.el-form-item__label {
    &::after {
      display: block;
      content: "(可多选)";
      margin-left: 15px;
      font-size: 12px;
      color: #cccccc;
    }
  }
}
</style>